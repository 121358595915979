'use client';

import './search-place-select.scss';

import cx from 'classix';
import debounce from 'lodash/debounce';
import { useTranslations } from 'next-intl';
import { type Ref } from 'react';
import {
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  BsArrowRight,
  BsGeoAltFill,
  BsXLg,
} from 'react-icons/bs';
import {
  type GroupBase,
  type InputActionMeta,
  type OptionsOrGroups,
  type SelectInstance,
  type SingleValue,
} from 'react-select';
import AsyncSelect from 'react-select/async';

import getPlacesAutocompleteByName from '@/features/place/requests/get-places-autocomplete-by-name';
import getUserCurrentPlace from '@/features/place/requests/get-user-current-place';
import { type Place } from '@/features/place/types/place-types';
import askUserLocation from '@/features/place/utils/ask-user-location';

import SearchPlaceInput from './search-place-input/search-place-input';
import SearchPlaceOptionNew from './search-place-option/search-place-option-new';
import styles from './search-place-select.module.scss';

interface SearchPlaceSelectProps {
  onShowNearMeClick: (userCurrentPlace: Place) => void;
  onChangeSelect: (selectedPlace: Place | null, foundPlaces: Place[] | null) => void;
  options?: Place[] | null;
  currentPlace?: Place | null;
  isNeedMoveForwardButton?: boolean;
  moveForwardButtonCallback?: () => void;
  isMainPage?: boolean;
  isClearValueShow?: boolean;
  ref?: Ref<SelectInstance<Place>>;
  variant?: 'rounded' | 'square';
}

export default function SearchPlaceSelect(props: SearchPlaceSelectProps) {
  const {
    currentPlace = null,
    isClearValueShow = true,
    isMainPage,
    isNeedMoveForwardButton,
    moveForwardButtonCallback,
    onChangeSelect,
    onShowNearMeClick,
    options,
    ref,
    variant = 'rounded',
  } = props;

  const translationsSearchPlace = useTranslations('place.search-place');

  const selectRef = useRef<SelectInstance<Place>>(null);

  // @ts-expect-error - example here https://react-hook-form.com/faqs#Howtosharerefusage, how to handle type don't know
  useImperativeHandle(ref, () => selectRef.current);

  const millisecondsBeforeSearchStart = 500;
  const minimumSymbolsToStartSearch = 2;

  const [inputValue,
    setInputValue] = useState<Place['fullName']>(currentPlace?.fullName ?? '');
  const [placeOptions,
    setPlaceOptions] = useState<Place[]>(options ?? []);
  const [needMoreToStartSearch,
    setNeedMoreToStartSearch] = useState<boolean>(false);
  const [nothingFound,
    setNothingFound] = useState(false);
  const [isShowSelectDropdown,
    setShowSelectDropdown] = useState(false);
  const [selectedOption,
    setSelectedOption] = useState<Place | null>(currentPlace);

  const getDropDownText = () => {
    if (needMoreToStartSearch) {
      return translationsSearchPlace('enter-more-symbols', { count: minimumSymbolsToStartSearch - 1 });
    }

    if (nothingFound) {
      return translationsSearchPlace('not-found');
    }

    return translationsSearchPlace('start-typing');
  };

  const getLoadingText = (inputData: { inputValue: string }) => (inputData.inputValue.length < minimumSymbolsToStartSearch
    ? translationsSearchPlace('enter-more-symbols', { count: minimumSymbolsToStartSearch - 1 })
    : translationsSearchPlace('loading'));

  const getPlaceName = (place: Place) => place.fullName;

  const getPlaceValue = (place: Place) => place.fullName;

  const handleShowNearMeClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const userCurrentCoords = await askUserLocation();
      const userCurrentPlace: Place = await getUserCurrentPlace(userCurrentCoords);

      setInputValue(userCurrentPlace.fullName);

      onShowNearMeClick(userCurrentPlace);
    })();
  };

  const handleLoadOptions = (value: string, callback: (options: OptionsOrGroups<Place, GroupBase<Place>>) => void) => {
    if (value.length >= minimumSymbolsToStartSearch) {
      getPlacesAutocompleteByName(value)
        .then((places) => {
          setNeedMoreToStartSearch(false);
          setNothingFound(places.length === 0);
          setPlaceOptions(places);

          callback(places);
        })
        .catch(() => null);
    } else {
      console.log('====> true');
      setNeedMoreToStartSearch(true);
    }
  };

  const debouncedLoadOptionsNew = useRef(debounce(handleLoadOptions, millisecondsBeforeSearchStart));

  const handleInputValueChange = (updatedInputValue: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action === 'input-change') {
      setInputValue(updatedInputValue);

      if (!isShowSelectDropdown) {
        setShowSelectDropdown(true);
      }
    }
  };

  const handleHideSelectDropdown = () => {
    setShowSelectDropdown(false);
  };

  const handleFocusSelect = () => {
    if (placeOptions.length > 0) {
      setShowSelectDropdown(true);
    }
  };

  const handleClearInputValue = () => {
    setInputValue('');
    setSelectedOption(null);
    onChangeSelect(null, null);

    if (selectRef.current) {
      selectRef.current.focus();
    }
  };

  const handleChangeSelect = (option: SingleValue<Place>) => {
    if (option?.fullName) {
      setInputValue(option.fullName);
      onChangeSelect(option, placeOptions);
      setSelectedOption(option);
      selectRef.current?.blur();
    }
  };

  useEffect(() => {
    if (options) {
      setPlaceOptions(options);
    }
  }, [options]);

  return (
    <div
      className={cx(
        'search_place_wrapper',
        isMainPage && 'searchPlace__isMainPage',
        isMainPage && styles.searchPlace__isMainPage,
        styles[`searchPlace__${variant}`],
        `searchPlace__${variant}`,
      )}
    >
      <button type="button" aria-label="search near" onClick={handleShowNearMeClick} className={styles.searchPlace_geoIcon}>
        <BsGeoAltFill />
      </button>

      <AsyncSelect<Place>
        ref={selectRef}
        classNamePrefix="search_place"
        instanceId="search-place"
        name="places"
        id="search-place"
        aria-label="place"
        placeholder={translationsSearchPlace('placeholder')}
        value={selectedOption}
        inputValue={inputValue}
        defaultInputValue={currentPlace?.fullName}
        escapeClearsValue={false}
        cacheOptions
        defaultOptions={placeOptions}
        onChange={handleChangeSelect}
        onInputChange={handleInputValueChange}
        onBlur={handleHideSelectDropdown}
        onFocus={handleFocusSelect}
        getOptionLabel={getPlaceName}
        getOptionValue={getPlaceValue}
        loadOptions={debouncedLoadOptionsNew.current}
        loadingMessage={getLoadingText}
        components={{
          Input: SearchPlaceInput,
          Option: SearchPlaceOptionNew,
        }}
        options={placeOptions}
        noOptionsMessage={getDropDownText}
        menuIsOpen={isShowSelectDropdown}
        isOptionSelected={(option) => option.fullName === selectedOption?.fullName}
      />

      {isClearValueShow && (
        <button
          type="button"
          aria-label="clear value"
          onClick={handleClearInputValue}
          className={cx(
            styles.searchPlace_clearIcon,
            !isNeedMoveForwardButton && styles.searchPlace_clearIcon__single,
          )}
        >
          {/* eslint-disable-next-line canonical/prefer-react-lazy */}
          <BsXLg strokeWidth={isMainPage ? 0.8 : 0.5} />
        </button>
      )}

      {isNeedMoveForwardButton && (
        <button
          className={cx(styles.searchPlace_forwardIcon)}
          name="next"
          aria-label="next"
          onClick={moveForwardButtonCallback}
          type="button"
        >
          {/* eslint-disable-next-line canonical/prefer-react-lazy */}
          <BsArrowRight strokeWidth={isMainPage ? 0.8 : 0.5} />
        </button>
      )}

    </div>
  );
}
