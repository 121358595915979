import cx from 'classix';
import { type IconType } from 'react-icons';

import styles from './notifications-indicator.module.scss';

interface NotificationIndicatorProps {
  icon?: IconType;
  text?: string | number;
  color?: 'green' | 'blue' | 'orange';
  view?: 'round' | 'rectangle';
}

export default function NotificationsIndicator(props: NotificationIndicatorProps) {
  const {
    color = 'green',
    icon,
    text,
    view = 'rectangle',
  } = props;

  return (
    <div
      className={cx(
        styles.notificationsIndicator,
        styles[`notificationsIndicator__${color}`],
        styles[`notificationsIndicator__${view}`],
      )}
    >
      {/* eslint-disable-next-line unicorn/no-keyword-prefix */}
      {icon?.({ className: styles.notificationsIndicator_icon })}
      {/* <FaBullhorn className={styles.headerLoginedLinks_messageIndicatorIcon} /> */}
      {text && (
        <div className={styles.notificationsIndicator_text}>
          {text}
        </div>
      )}
    </div>
  );
}
